import React from 'react';
import './ContactMe.css';
import { FaInstagram, FaPhone } from 'react-icons/fa';

const InstagramEmbed = ({ postId }) => {
  const embedUrl = `https://www.instagram.com/p/${postId}/embed`;
  return (
    <div className="post-container">
      <iframe
        src={embedUrl}
        title={postId}
        className="instagram-iframe"
        allowFullScreen={false}
      ></iframe>
    </div>
  );
};

const ContactMe = ({ brokerUsername, phoneNumber, postId, instagramURL }) => {
  return (
    <div className="contact-me-container mt-5 mb-5 px-1 mx-auto justify-content-center text-center">
      <div className="contact-box">
        <div className="pt-5 pb-5 contact-me-title">LET'S GET IN TOUCH!</div>
        <div className="post-container mx-auto"> 
          <div className="post-container">
            <InstagramEmbed postId={postId} />
          </div>
        </div>
        <div className="row align-items-center mx-5">
          <div className="col-6 mx-auto left-column m-3">
            <FaInstagram size={150} />
            <div href={instagramURL} target="_blank" rel="noopener noreferrer">
              <div>@{brokerUsername}</div>
            </div>
          </div>
          <div className="col-6 mx-auto right-column m-3">
            <FaPhone size={130} />
            <div>{phoneNumber}</div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default ContactMe;
