import React, { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';
import './Sponsors.css';
import druBruLogo from '../../../images/dru_bru_logo.png';
import mmStackedLogo from '../../../images/mm_stacked_logo.png';
import momentumVansLogo from '../../../images/momentum_vans_logo.png';
import pattersonCellarsLogo from '../../../images/patterson_cellars_logo.png';
import woodridgeLogo from '../../../images/woodridge_logo.png';

const imageArr = [woodridgeLogo, mmStackedLogo, momentumVansLogo, pattersonCellarsLogo, druBruLogo];

const Sponsors = () => {
  useEffect(() => {
    ScrollReveal().reveal('.sponsors-sponsor-box', {
      viewFactor: 0.4,
      opacity: 0,
      duration: 2500,
      reset: false
    });
  }, []);

  return (
    <div className="sponsors-container">
      <div>
        <h1 className="sponsors-title">FEATURED PARTNERS</h1>
        <div className="sponsors-page-divider"/>
      </div>
      <div className="sponsors-grid">
        <div className="row justify-content-center">
          {imageArr.map((image, index) => (
            <div key={index} className="col-md-4 col-sm-6 col-12">
              <div className="sponsors-sponsor-box">
                <img src={image} alt="sponsor" className="img-fluid sponsors-sponsorbox-inner"/>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sponsors;
