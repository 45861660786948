import React, { useState } from 'react';
import './LeadGenerationForm.css'

const NeighborhoodSection = ({ handleProgress, neighborhood1, neighborhood2, neighborhood3, neighborhood4 }) => {
  const initialState = {};
  neighborhood1 = neighborhood1 || "Downtown";
  neighborhood2 = neighborhood2 || "Uptown";
  neighborhood3 = neighborhood3 || "Suburbs";
  neighborhood4 = neighborhood4 || "Other";
  const neighborhoods = [
    neighborhood1,
    neighborhood2,
    neighborhood3,
    neighborhood4,
  ];
  neighborhoods.forEach(neighborhood => initialState[neighborhood] = false);

  const [selectedNeighborhoods, setSelectedNeighborhoods] = useState(initialState);

  const handleNeighborhoodChange = (neighborhood) => {
    selectedNeighborhoods[neighborhood] = !selectedNeighborhoods[neighborhood]
    setSelectedNeighborhoods(selectedNeighborhoods);
    const isCompleted = Object.values(selectedNeighborhoods).some(value => value);
    handleProgress('neighborhood', isCompleted, selectedNeighborhoods);
  };

  return (
    <section>
      <div className='lead-gen-title mt-lg-5 mb-lg-5'>What neighborhood or area are you interested in?</div>
      <div className="row mt-4 mt-md-5 mb-1">
        {neighborhoods.map((neighborhood, index) => (
          <div key={index} className="col-12 col-sm-6 p-3 px-1">
            <label htmlFor={"choose-"+neighborhood}>
              <input
                className="lead-gen-neighborhood-selection-checkbox"
                type="checkbox"
                id={"choose-"+neighborhood}
                name={neighborhood}
                value={neighborhood}
                checked={selectedNeighborhoods[neighborhood] || false}
                onChange={() => handleNeighborhoodChange(neighborhood)}
              />
              <div className="label lead-gen-neighborhood-checkbox-text">{neighborhood}</div>
            </label>
          </div>
        ))}
      </div>
    </section>
  );
};

export default NeighborhoodSection;
