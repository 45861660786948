import { isFieldComplete } from './utils/formUtils';

const BudgetSection = ({ handleProgress, budget }) => {
  const handleBudgetChange = (e) => {
    const selectedBudget = e.target.value;
    const isCompleted = isFieldComplete(selectedBudget); // Using the isFieldComplete function
    handleProgress('budget', isCompleted, selectedBudget);
  };

  const budgetOptions = [
    "$500k - $1m",
    "$1m - $1.5m",
    "$1.5m - $2m",
    "$2m - $2.5m",
    "$2.5m+",
  ];

  return (
    <div>
      <div className="lead-gen-title mt-lg-5 mb-lg-5">What is your budget range?</div>
      <select className="mb-5" onChange={handleBudgetChange} value={budget}>
        <option value="">Select budget</option> {/* Non-completed default value */}
        {budgetOptions.map((option, index) => (
          <option key={index} value={option}>
            {option || "Select budget"}
          </option>
        ))}
      </select>
    </div>
  );
};

export default BudgetSection;
