import React, { useState } from 'react';

const InvestingOptionsSection = ({ handleProgress }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const investingOptions = [
    'Short-term rental',
    'Long-term rental',
    'Fix-and-flip',
    'Other',
  ];

  const handleInvestingOptionClick = (selectedOption) => {
    setSelectedOption(selectedOption);
    handleProgress('investing', true, selectedOption);
  };

  return (
    <div>
      <div className="lead-gen-title mt-lg-5 mb-lg-4">What are your investing interests?</div>
      <div className="row">
        <div className="row align-items-center justify-content-center text-center mb-4">
          {investingOptions.map((option, index) => (
            <div className="col-12 col-sm-6 p-3">
              <button 
                key={index} 
                type="button"
                onClick={() => handleInvestingOptionClick(option)}
                className={selectedOption === option ? 'lead-gen-investing-interest-button selected' : 'col-6 lead-gen-investing-interest-button'}
              >
                <div className="lead-gen-investing-interest-text"> {option} </div>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InvestingOptionsSection;
