import React from 'react';
import './ProgressBar.css';

const ProgressBar = ({ progress }) => (
  <div className="progress-bar" role="progressbar">
    <div className="progress" style={{ width: `${progress}%` }}>
      <div className="liquid"></div>
    </div>
  </div>
);

export default ProgressBar;
