import React, { useState } from 'react';
import './WelcomeSection.css';
import InstagramLogo from '../../../images/icons/instagram.png';
import FacebookLogo from '../../../images/icons/facebook.png';
import YoutubeLogo from '../../../images/icons/youtube.png';
import { FaYoutube } from 'react-icons/fa';
import Logo from '../../../images/logo.png'
import LeadGeneration from './LeadGeneration';

const WelcomeSection = ({title, photo, area, episodeURL, brokerYoutubeURL, brokerInstagramURL, brokerFacebookURL, nbhood1, nbhood2, nbhood3, nbhood4 = 'Other'}) => {
  const [userInterest, setUserInterest] = useState(null);

  const handleInterestChange = (newInterest) => {
    if (newInterest !== userInterest) {
      setUserInterest(newInterest);
    }
  };

  return (
    <div className="welcome-container">
      <div className="welcome-card">
        <div className="row justify-content-center">
          <div className="col-12 pb-2 pt-2 pt-sm-0">
            <h2 className="welcome-title">{title}</h2>
          </div>
          <div className="col-lg-5 col-md-6 col-12">
            <img src={photo} alt="Profile" className="welcome-profile-pic img-fluid"/>
            <div className="row social-icons-bar text-center justify-content-center p-1">
              <div className="col-4 social-bar-icon-container">
                <a href={brokerYoutubeURL} target="_blank" rel="noopener noreferrer">
                  <img class="social-bar-icon" src={YoutubeLogo} alt="Youtube" />
                </a>
              </div>
              <div className="col-4 social-bar-icon-container">
                <a href={brokerInstagramURL} target="_blank" rel="noopener noreferrer">
                  <img class="social-bar-icon" src={InstagramLogo} alt="Instagram" />
                </a>
              </div>
              <div className="col-4 social-bar-icon-container">
                <a href={brokerFacebookURL} target="_blank" rel="noopener noreferrer">
                  <img class="social-bar-icon" src={FacebookLogo} alt="Facebook" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-6 col-sm-12 mt-2 mt-md-0">

            <a href={episodeURL} target="_blank" rel="noopener noreferrer" className="welcome-youtube-link">
              <div className="welcome-episode-box">
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-4 col-md-5 col-sm-4 col-4">
                    <img src={Logo} alt="Logo" className="img-fluid welcome-logo"/>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-8 col-8">
                    <h3 className="welcome-episode-text">MASTER PLAN {area}</h3>
                    <div className="d-flex align-items-center youtube-text-container">
                      <FaYoutube className="youtube-icon"/>
                      <div className="episode-link-text">Missed the Episode? Click here!</div>
                    </div>
                  </div>
                </div>
              </div>
            </a>

            <div className="row mt-2">
              <div className="col-sm-6 col-xl-6 col-md-12 col-12 mb-2">
                <button className="welcome-btn py-3 py-md-1 py-xl-5 px-2" style={{ backgroundColor: 'rgba(154, 0, 0, 0.1)' }} onClick={() => handleInterestChange('Buying')}><div className="pt-2 pl-1">BUYING</div></button>
              </div>
              <div className="col-sm-6 col-xl-6 col-md-12 col-12 mb-2">
                <button className="welcome-btn py-3 py-md-1 py-xl-5 px-2" style={{ backgroundColor: 'rgba(0, 24, 0, 0.1)' }} onClick={() => handleInterestChange('Selling')}><div className="pt-2 pl-1">SELLING</div></button>
              </div>
              <div className="col-sm-6 col-xl-6 col-md-12 col-12 mb-2">
                <button className="welcome-btn py-3 py-md-1 py-xl-5 px-2" style={{ backgroundColor: 'rgba(108, 59, 0, 0.1)' }} onClick={() => handleInterestChange('Relocating')}><div className="pt-2 pl-1">RELOCATING</div></button>
              </div>
              <div className="col-sm-6 col-xl-6 col-md-12 col-12 mb-2">
                <button className="welcome-btn py-3 py-md-1 py-xl-5 px-2" style={{ backgroundColor: 'rgba(36, 0, 65, 0.1)' }} onClick={() => handleInterestChange('Investing')}><div className="pt-2 pl-1">INVESTING</div></button>
              </div>
            </div>

            <p className="welcome-question-text d-none d-xl-block">QUESTIONS ABOUT THE {area} MARKET? LET'S CONNECT!</p>
          </div>
          <div className="col-10 d-xl-none">
            <p className="welcome-question-text">QUESTIONS ABOUT THE {area} MARKET? LET'S CONNECT!</p>
          </div>
        </div>
      </div>
      {userInterest && <LeadGeneration userInterest={userInterest} broker={title} nbhood1={nbhood1} nbhood2={nbhood2} nbhood3={nbhood3} nbhood4={nbhood4} />}
    </div>
  );
};


export default WelcomeSection;
