import React, { useState } from 'react';

const ContactDetailsSection = ({ handleProgress }) => {
  const [contactPreferences, setContactPreferences] = useState({ text: false, call: false, email: false });
  const [formData, setFormData] = useState({ name: '', email: '', phone: '' });

  const togglePreference = (type) => {
    setContactPreferences(prevState => ({ ...prevState, [type]: !contactPreferences[type] }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = () => {
    const { name, email, phone } = formData;
    const isCompleted = name.trim() !== '' && email.trim() !== '' && phone.trim() !== '';
    handleProgress('contactDetails', isCompleted, { name, email, phone, contactPreferences });
  };

  return (
    <div className="mt-lg-3 mb-lg-3">
      <div className="pt-4 m-1">
        <div className="lead-gen-title">Name</div>
        <div className="contact-details-page-divider"/>
        <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
      </div>

      <div className="m-1">
        <div className="lead-gen-title pt-4">Email</div>
        <div className="contact-details-page-divider"/>
        <input type="email" name="email" value={formData.email} onChange={handleInputChange} />
      </div>

      <div className="m-1">
        <div className="lead-gen-title pt-4">Phone</div>
        <div className="contact-details-page-divider"/>
        <input type="tel" name="phone" value={formData.phone} onChange={handleInputChange} />
      </div>

      <div className="lead-gen-title p-1 p-sm-4">Contact Preferences</div>
      <div className="row p-3 justify-content-center">
        {['text', 'call', 'email'].map(pref => (
          <button
            key={pref}
            type="button"
            onClick={() => togglePreference(pref)}
            className={`lead-gen-button col-12 col-sm-3 p-4 ${contactPreferences[pref] ? 'selected' : ''}`}
          >
            {pref.charAt(0).toUpperCase() + pref.slice(1)}
          </button>
        ))}
      </div>
      <button className="lead-gen-button p-5" type="submit" onClick={() => handleSubmit()}>Submit</button> {/* TODO This button does no validation need to fix */}
    </div>
  );
};

export default ContactDetailsSection;
