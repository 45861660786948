import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Screens/Home';
import ShawnTate from './components/Screens/ShawnTate';
import KaoriNagoa from './components/Screens/KaoriNagao';
import SabineJacques from './components/Screens/SabineJacques';
import BrianHopper from './components/Screens/BrianHopper';
import BridgetRamey from './components/Screens/BridgetRamey';
import P2Crew from './components/Screens/P2Crew';
import DanRedwine from './components/Screens/DanRedwine';
import DebbieJames from './components/Screens/DebbieJames';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ShawnTate" element={<ShawnTate />} />
        <Route path="/KaoriNagoa" element={<KaoriNagoa />} />
        <Route path="/SabineJacques" element={<SabineJacques />} />
        <Route path="/BrianHopper" element={<BrianHopper />} />
        <Route path="/BridgetRamey" element={<BridgetRamey />} />
        <Route path="/P2Crew" element={<P2Crew />} />
        <Route path="/DanRedwine" element={<DanRedwine />} />
        <Route path="/DebbieJames" element={<DebbieJames />} />
      </Routes>
    </Router>
  );
}

export default App;
