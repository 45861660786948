import React from 'react';
import './NavMenu.css';
import DropdownMenu from './DropdownMenu';
import SocialMediaIcons from './SocialMediaIcons';
import { useNavigate } from 'react-router-dom';

const NavMenu = () => {
  const navigate = useNavigate();

  const episodesItems = [
    { label: "30A, FL", url: "https://youtu.be/vdFqOJ0gZfs" },
    { label: "CLE ELUM, WA", url: "https://www.youtube.com/watch?v=a5D6GDV76Sk" },
    { label: "DESERT MT, AZ", url: "https://www.youtube.com/watch?v=YsMY3qAwAiQ" },
    { label: "AUSTIN, TX", url: "https://www.youtube.com/watch?v=ehv30-ADuwo" },
    { label: "BELLEVUE, WA", url: "https://www.youtube.com/watch?v=0mSAxXM-RpE" },
    { label: "NASHVILLE, TN", url: "https://www.youtube.com/watch?v=WcgPWBnhD9g" },
    { label: "LAS VEGAS, NV", url: "https://www.youtube.com/watch?v=EXBwOIkwrSc" },
    { label: "WOODINVILLE, WA", url: "https://www.youtube.com/watch?v=RBZV_uW4AOs" },
    { label: "LEAVENWORTH, WA", url: "https://www.youtube.com/watch?v=UIbHlSwRMhE" },
    { label: "ROSLYN, WA", url: "https://www.youtube.com/watch?v=Yx4kdsyQPaY" },
    { label: "SUNCADIA, WA", url: "https://www.youtube.com/watch?v=y9RzGDhJw94" },
    { label: "TUMBLE CREEK, WA", url: "https://www.youtube.com/watch?v=SW7-Rz1vIvk" }
  ];

  const hostsItems = [
    { label: "DEBBIE JAMES", path: "/DebbieJames" },
    { label: "DAN REDWINE", path: "/DanRedwine" },
    { label: "P2 CREW", path: "/P2Crew"  },
    { label: "BRIDGET RAMEY", path: "/BridgetRamey"  },
    { label: "BRIAN HOPPER", path: "/BrianHopper"  },
    { label: "SHAWN TATE", path: "/ShawnTate"  },
    { label: "KAORI NAGAO", path: "/KaoriNagoa"  },
    { label: "SABINE JACQUES", path: "/SabineJacques"  }
  ];

  const episodeActions = episodesItems.map(item => () => {
    window.location.href = item.url;
  });

  const hostActions = hostsItems.map(item => () => {
    navigate(item.path);
  });

  return (
    <div className="nav-menu row pt-4">
      <div className="col-3">
        <DropdownMenu title="FULL EPISODES" items={episodesItems} actions={episodeActions}/>
      </div>
      <div className="col-3">
        <DropdownMenu title="MPTV HOSTS" items={hostsItems} actions={hostActions}/>
      </div>
      <div className="col-6 d-flex justify-content-end">
        <SocialMediaIcons 
          youtubeLink="https://www.youtube.com/@themasterplantv"
          facebookLink="https://www.facebook.com/themasterplan.tv/"
          instagramLink="https://www.instagram.com/masterplan_media/"
        />
      </div>
    </div>
  );
};

export default NavMenu;
