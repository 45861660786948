import React from 'react';
import './PartnerCard.css';

const PartnerCard = ({ image, name, location, office }) => {
  return (
    <div className="partner-card p-3">
      <div className="partner-image-container">
        <img src={image} alt={`${name}: ${location}`} className="partner-image"/>
      </div>
      <div className="partner-info">
        <span className="partner-name">{name}</span>
        <p className="partner-office">{office}</p>
        <h2 className="partner-location">{location}</h2>
      </div>
    </div>
  );
};

export default PartnerCard;
