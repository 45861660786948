import React from 'react';
import WelcomeSection from '../BrokerPage/WelcomeSection/WelcomeSection';
import TrustedNetwork from '../BrokerPage/TrustedNetwork/TrustedNetwork';
import ContactMe from '../BrokerPage/ContactMe/ContactMe';
import bridget from '../../images/bridget-ramey.png'
import p2crew from '../../images/p2crew.jpg'
import brian from '../../images/brianhopper.png'
import dan from '../../images/dan_cle_elum.JPG'
import kaori from '../../images/kaorinagao.png'
import sabine from '../../images/sabinejacques.png'
import shawn from '../../images/ShawnTate.jpg'
import thirtya from '../../images/30a.jpg'

import useScrollToTop from '../hooks/useScrollToTop';

const brokerName = "Bridget Ramey";
const episodeArea = 'Austin';
const episodeURL = 'https://www.youtube.com/watch?v=ehv30-ADuwo';
const phone = '512-796-2602';
const featureInstaPostID = 'CsFCXDRJVSF'

const BridgetRamey = () => {
  useScrollToTop();
  return (
    <div className="bridget-ramey">
      <WelcomeSection
        title={brokerName.toUpperCase()}
        photo={bridget}
        area={episodeArea.toUpperCase()}
        episodeURL={episodeURL}
        brokerYoutubeURL={"https://www.youtube.com/@Bridget-Ramey"}
        brokerInstagramURL={"https://www.instagram.com/bridgetramey/"}
        brokerFacebookURL={"https://www.facebook.com/BridgetRameyRealtor/"}
        nbhood1={"Greater Austin"}
        nbhood2={"Downtown"}
        nbhood3={"Lake Austin"}
      />
      <TrustedNetwork
        title="The Master Plan Broker Network"
        brokerData={[
          {
            image: thirtya,
            name: "Debbie James",
            location: "30A, Florida",
            office: "Scenic Sotheby's"
          },
          {
            image: brian,
            name: "Brian Hopper",
            location: "Bellevue, Washington",
            office: "Realogics Sotheby's"
          },
          {
            image: p2crew,
            name: "P2Crew",
            location: "Desert Mountain, Arizona",
            office: "Russ Lyon Sotheby's"
          },
          {
            image: sabine,
            name: "Sabine Jacques",
            location: "Woodinville, Washington",
            office: "Realogics Sotheby's"
          },
          {
            image: kaori,
            name: "Kaori Nagao",
            location: "Las Vegas, Nevada",
            office: "Las Vegas Sotheby's"
          },
          {
            image: shawn,
            name: "Shawn Tate",
            location: "Nashville, Tennessee",
            office: "Zeitlin Sotheby's"
            },
          {
            image: dan,
            name: "Dan Redwine",
            location: "Cle Elum, Washington",
            office: "Realogics Sotheby's"
          }
        ]}
      />
      <ContactMe brokerUsername="bridgetramey" phoneNumber={phone} postId={featureInstaPostID} instagramURL='https://www.instagram.com/bridgetramey/' />
    </div>
  )
};

export default BridgetRamey;