import React from 'react';
import WelcomeSection from '../BrokerPage/WelcomeSection/WelcomeSection';
import TrustedNetwork from '../BrokerPage/TrustedNetwork/TrustedNetwork';
import ContactMe from '../BrokerPage/ContactMe/ContactMe';
import bridget from '../../images/bridget-ramey.png'
import p2crew from '../../images/p2crew.jpg'
import brian from '../../images/brianhopper.png'
import dan from '../../images/dan_cle_elum.JPG'
import shawn from '../../images/ShawnTate.jpg'
import sabine from '../../images/sabinejacques.png'
import kaori from '../../images/kaorinagao.png'
import thirtya from '../../images/30a.jpg'

import useScrollToTop from '../hooks/useScrollToTop';

const brokerName = "Kaori Nagao";
const episodeArea = 'Las Vegas';
const episodeURL = 'https://www.youtube.com/watch?v=EXBwOIkwrSc';

const KaoriNagao = () => {
  useScrollToTop();

  return (
    <div className="kaori-nagao">
        <WelcomeSection
            title={brokerName.toUpperCase()}
            photo={kaori}
            area={episodeArea.toUpperCase()}
            episodeURL={episodeURL}
            brokerYoutubeURL={"https://www.youtube.com/channel/UCcAQFeRQEludc5wjj3D7RFw"}
            brokerInstagramURL={"https://www.instagram.com/kaoriluxuryrealestate/"}
            brokerFacebookURL={"https://www.facebook.com/KaoriLuxuryRealEstate/"}
            nbhood1={"Summerlin"}
            nbhood2={"Henderson"}
            nbhood3={"The Strip"}
        />
        <TrustedNetwork
            title="The Master Plan Broker Network"
            brokerData={[
              {
                image: thirtya,
                name: "Debbie James",
                location: "30A, Florida",
                office: "Scenic Sotheby's"
              },
              {
                image: bridget,
                name: "Bridget Ramey",
                location: "Austin, Texas",
                office: "Kuper Sotheby's"
              },
              {
                image: p2crew,
                name: "P2Crew",
                location: "Desert Mountain, Arizona",
                office: "Russ Lyon Sotheby's"
              },
              {
                image: brian,
                name: "Brian Hopper",
                location: "Bellevue, Washington",
                office: "Realogics Sotheby's"
              },
              {
                image: sabine,
                name: "Sabine Jacques",
                location: "Woodinville, Washington",
                office: "Realogics Sotheby's"
              },
              {
                image: shawn,
                name: "Shawn Tate",
                location: "Nashville, Tennesse",
                office: "Zeitlin Sotheby's "
                },
              {
                image: dan,
                name: "Dan Redwine",
                location: "Cle Elum, Washington",
                office: "Realogics Sotheby's"
              }
            ]}
        />
        <ContactMe brokerUsername="kaoriluxuryrealestate" phoneNumber="702-577-7659" postId='CvusMSoL_sc' instagramURL='https://www.instagram.com/kaoriluxuryrealestate/' />
    </div>
  )
};

export default KaoriNagao;