import React from 'react';
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
import './SocialMediaIcons.css';

const SocialMediaIcon = ({ href, children }) => (
  <a href={href} className="icon-button" target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const SocialMediaIcons = ({ youtubeLink, facebookLink, instagramLink }) => (
  <div className="social-icons-container">
    <div className="row text-center">
      <div className="col-4">
        <SocialMediaIcon href={youtubeLink}>
          <FaYoutube />
        </SocialMediaIcon>
      </div>
      <div className="col-4">
        <SocialMediaIcon href={facebookLink}>
          <FaFacebookF />
        </SocialMediaIcon>
      </div>
      <div className="col-4">
        <SocialMediaIcon href={instagramLink}>
          <FaInstagram />
        </SocialMediaIcon>
      </div>
    </div>
  </div>
);

export default SocialMediaIcons;