import React from 'react';
import WelcomeSection from '../BrokerPage/WelcomeSection/WelcomeSection';
import TrustedNetwork from '../BrokerPage/TrustedNetwork/TrustedNetwork';
import ContactMe from '../BrokerPage/ContactMe/ContactMe';
import bridget from '../../images/bridget-ramey.png'
import p2crew from '../../images/p2crew.jpg'
import brian from '../../images/brianhopper.png'
import dan from '../../images/dan_cle_elum.JPG'
import kaori from '../../images/kaorinagao.png'
import sabine from '../../images/sabinejacques.png'
import shawn from '../../images/ShawnTate.jpg'
import thirtya from '../../images/30a.jpg'

import useScrollToTop from '../hooks/useScrollToTop';

const brokerName = "Sabine Jacques";
const episodeArea = 'Woodinville';
const episodeURL = 'https://www.youtube.com/watch?v=RBZV_uW4AOs';

const SabineJacques = () => {
  useScrollToTop();
  return (
    <div className="sabine-jacques">
      <WelcomeSection
        title={brokerName.toUpperCase()}
        photo={sabine}
        area={episodeArea.toUpperCase()}
        episodeURL={episodeURL}
        brokerYoutubeURL={"https://www.youtube.com/watch?v=o_o6Xq8RkRQ"}
        brokerInstagramURL={"https://www.instagram.com/sabinejacques_rsir/"}
        brokerFacebookURL={"https://www.facebook.com/sabinejacquesrealestate/"}
        nbhood1={"Woodinville"}
        nbhood2={"Kirkland"}
        nbhood3={"Redmond"}
      />
      <TrustedNetwork
        title="The Master Plan Broker Network"
        brokerData={[
          {
            image: thirtya,
            name: "Debbie James",
            location: "30A, Florida",
            office: "Scenic Sotheby's"
          },
          {
            image: bridget,
            name: "Bridget Ramey",
            location: "Austin, Texas",
            office: "Kuper Sotheby's"
          },
          {
            image: p2crew,
            name: "P2Crew",
            location: "Desert Mountain, Arizona",
            office: "Russ Lyon Sotheby's"
          },
          {
            image: brian,
            name: "Brian Hopper",
            location: "Bellevue, Washington",
            office: "Realogics Sotheby's"
          },
          {
            image: kaori,
            name: "Kaori Nagao",
            location: "Las Vegas, Nevada",
            office: "Las Vegas Sotheby's"
          },
          {
            image: shawn,
            name: "Shawn Tate",
            location: "Nashville, Tennessee",
            office: "Zeitlin Sotheby's "
            },
          {
            image: dan,
            name: "Dan Redwine",
            location: "Cle Elum, Washington",
            office: "Realogics Sotheby's"
          }
        ]}
      />
      <ContactMe brokerUsername="sabinejacques_rsir" phoneNumber="425-208-2827" postId='Cxg3IuyNc_s' instagramURL='https://www.instagram.com/sabinejacques_rsir/' />
    </div>
  )
};

export default SabineJacques;