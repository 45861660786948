import React from 'react';
import PartnerCard from './PartnerCard';
import './TrustedNetwork.css';

const TrustedNetwork = ({title, brokerData}) => {
  return (
    <div className="trusted-network-container p-4">
      <h1 className="trusted-network-title mb-4 mt-1">{title}</h1>
      <div className="row justify-content-center">
        {brokerData.map((partner, index) => (
          <div key={index} className="col-12 col-lg-4 col-sm-6 p-2 px-1">
            <PartnerCard {...partner}/>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrustedNetwork;
