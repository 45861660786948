import React from 'react';
import WelcomeSection from '../BrokerPage/WelcomeSection/WelcomeSection';
import TrustedNetwork from '../BrokerPage/TrustedNetwork/TrustedNetwork';
import ContactMe from '../BrokerPage/ContactMe/ContactMe';
import bridget from '../../images/bridget-ramey.png'
import p2crew from '../../images/p2crew.jpg'
import brian from '../../images/brianhopper.png'
import dan from '../../images/dan_cle_elum.JPG'
import kaori from '../../images/kaorinagao.png'
import sabine from '../../images/sabinejacques.png'
import shawn from '../../images/ShawnTate.jpg'
import thirtya from '../../images/30a.jpg'

import useScrollToTop from '../hooks/useScrollToTop';

const brokerName = "Debbie James";
const episodeArea = '30A';
const episodeURL = 'https://youtu.be/vdFqOJ0gZfs';

const DebbieJames = () => {
    useScrollToTop();
    return (
      <div className="debbiejames">
        <WelcomeSection
          title={brokerName.toUpperCase()}
          photo={thirtya}
          area={episodeArea.toUpperCase()}
          episodeURL={episodeURL}
          brokerYoutubeURL={"https://www.youtube.com/@debbiejamesrealtor"}
          brokerInstagramURL={"https://www.instagram.com/debbiejames30a/"}
          brokerFacebookURL={"https://www.facebook.com/DebbieJames30A"}
          nbhood1={"Seagrove"}
          nbhood2={"Seacrest"}
          nbhood3={"Watersound"}
          nbhood4={"Rosemary Beach"}
        />
        <TrustedNetwork
          title="The Master Plan Broker Network"
          brokerData={[
            {
              image: brian,
              name: "Brian Hopper",
              location: "Bellevue, Washington",
              office: "Realogics Sotheby's"
            },
            {
              image: bridget,
              name: "Bridget Ramey",
              location: "Austin, Texas",
              office: "Kuper Sotheby's"
            },
            {
              image: sabine,
              name: "Sabine Jacques",
              location: "Woodinville, Washington",
              office: "Realogics Sotheby's"
            },
            {
              image: kaori,
              name: "Kaori Nagao",
              location: "Las Vegas, Nevada",
              office: "Las Vegas Sotheby's"
            },
            {
              image: shawn,
              name: "Shawn Tate",
              location: "Nashville, Tennessee",
              office: "Zeitlin Sotheby's "
              },
            {
              image: dan,
              name: "Dan Redwine",
              location: "Cle Elum, Washington",
              office: "Realogics Sotheby's"
            },
            {
              image: p2crew,
              name: "P2 Crew",
              location: "Desert Mountain, Arizona",
              office: "Russ Lyon Sotheby's"
            }
          ]}
        />
        <ContactMe brokerUsername="Debbie James" phoneNumber="(850) 450-2400" postId='C8wxwj2JCa7' instagramURL='https://www.instagram.com/debbiejames30a/' />
      </div>
    )
};

export default DebbieJames;