import React from 'react';
import HeroSection from '../HomePage/HeroSection/HeroSection';
// import FeaturedPersonsSection from '../HomePage/FeaturedPersonsSection/FeaturedPersonsSection';
import EpisodeCarouselSection from '../HomePage/EpisodeCarouselSection/EpisodeCarouselSection';
import VideoCarousel from '../HomePage/VideoCarouselSection/VideoCarousel';
import Sponsors from '../HomePage/SponsorsSection/Sponsors';
import reel1 from '../../videos/reel1.mp4';
import reel2 from '../../videos/reel2.mp4';
import reel3 from '../../videos/reel3.mp4';
import reel4 from '../../videos/reel4.mp4';
import reel5 from '../../videos/reel5.mp4';
import reel6 from '../../videos/reel6.mp4';
import reel7 from '../../videos/reel7.mp4';
import NavMenu from '../HomePage/HeroSection/NavMenu';

// import dan from '../../images/danredwine.png';
// import rachael from '../../images/rachael.jpg';

// const danDescription = [
//   "Executive Producer",
//   "Go-to-coffee: Drip with Whip",
//   "Guilty binge watch: The Office",
//   "Best Happy Hour: 520 Grill",
//   "All-time movie: The Princess Bride",
//   "Favorite Team: Seattle Sounders",
//   "Adult Bev: Red Red wine of course!"
// ];

const Home = () => {

  return (
    <div className="page-home">
      <NavMenu />
      <HeroSection />
      <EpisodeCarouselSection />
      {/* <FeaturedPersonsSection personPic={dan} personTitle="Meet Dan Redwine" personDescription={danDescription} swapColumns={false}/> */}
      {/* <FeaturedPersonsSection personPic={rachael} personTitle="Meet Rachael Ann" personDescription={danDescription} swapColumns={true}/> */}
      <VideoCarousel videoList={[reel2, reel1, reel3, reel4, reel5, reel6, reel7]} />
      {/*<SothebysNetwork />*/}
      <Sponsors />
    </div>
  );
};

export default Home;
